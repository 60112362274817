import React, { Component } from "react";
import Invoice from "./Invoice.js";
import utilities from "../../helpers/utilities";
export default class ListRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataStatus: "waiting",
      records: [],
      selectedLot: null,
      optionList: [],
      optionListObj: {},
      readSubmission: {},
      resident: {},
      selectedQuarter: null,
      message: null,
      totals:{}
    };
  }
  // if (this.state.activeComponent === "viewlog") {
  //   console.log("got here");
  //   utilities.getMeterReadings("none", "2019-01-01", "2020-02-01").then((resp) => {
  //     this.setState({ records: resp.response, dataStatus: "ready" });
  //   });
  // }
  componentDidMount() {
    this.buildOptions();
    this.getCurrentDate();
  }
  getCurrentDate() {
    const d = new Date();
    const y = d.getFullYear();
    const q = Math.floor((d.getMonth() + 3) / 3);
    this.setState({ currentYear: y, currentQuarter: q });
  }
  buildOptions(props) {
    return utilities.getLots().then((resp) => {
      console.log("16: ", resp.response);
      let optionList = [];
      this.setState({ optionList: resp.response, dataStatus: "waiting", optionListObj: resp.records });
    });
  }
  handleLotChange(e) {
    e.preventDefault();
    this.setState({
      selectedLot: e.target.value,
      selectedLotDetails: this.state.optionListObj[e.target.value],
    });
  }
  handleInputChange(e, el) {
    if (el == "read_date") {
      let index = e.nativeEvent.target.selectedIndex;

      this.setState({ [el]: e.target.value, selectedQuarter: e.nativeEvent.target[index].text.split(" ")[1] });
    } else {
      this.setState({ [el]: e.target.value });
    }

    if (this.state.selectedLot != null) {
      this.setState({ submitDisabled: false });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      readSubmission: {
        status: "",
        response: "",
        CCF: "",
        gallons: "",
        amount_owed: 0,
      },
      dataStatus: "loading",
    });
    utilities.getMeterReadings(this.state.selectedLotDetails.lot).then((resp) => {
      var result = resp.response.reduce(function(acc, v) {
        console.log("line 76: ",v);
        //acc[v.lot_number_search] = (acc[v.lot_number_search] || 0) + v.amount_owed;
        return  acc + v.amount_owed;
      },0);
      var balance = resp.response.reduce(function(acc, v) {
        console.log("line 76: ",v);
        //acc[v.lot_number_search] = (acc[v.lot_number_search] || 0) + v.amount_owed;
        return  acc + v.balance;
      },0);
      console.log("line 79: ", result);
      this.setState({ totals: {"owed": result, "balance": balance} });
    });
    utilities
      .getMeterReadings(this.state.selectedLotDetails.lot, this.state.read_date, false)
      .then((resp) => {
        console.log("line 86: ", resp);
        this.setState({
          // readSubmission: {
          //   CCF: resp.response[0].CCF_calc,
          //   gallons: resp.response[0].gallons_calc,
          //   amount_owed: resp.response[0].amount_owed,
          //   amount_paid: resp.response[0].amount_paid,
          //   balance: resp.response[0].balance,
          // },
          readSubmission: resp.response,
          submitDisabled: true,
          dataStatus: "ready",
          message: null,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          readSubmission: {
            display: "display",
            status: "Sorry, an error occured",
            CCF: "Unknown",
            gallons: "Unknown",
            amount_owed: 0,
          },
          dataStatus: "waiting",
          message: err.message,
        });
      });
    utilities
      .getResidents(this.state.selectedLotDetails.residents[0])
      .then((resp) => {
        console.log(resp);
        this.setState({ resident: resp.records["fields"] });
      })
      .catch((err) => {});
  }
  // renderRow(result = []) {
  //   console.log("58: ", result);
  //     console.log("60: ", result["lot_number_search"]);
  //     this.setState({dataStatus: "ready"})
  //     return (
  //       <tr key={result["index"]}>
  //         <td>{result["lot_number_search"]}</td>
  //         <td>
  //           <Moment format="MM/DD/YYYY">{result["reading_date"]}</Moment>
  //         </td>
  //         <td>{result["previous_reading"]}</td>
  //         <td>{result["current_reading"].toFixed(2)}</td>
  //         <td>{result["CCF_calc"].toFixed(2)}</td>
  //         <td>{result["gallons_calc"].toFixed(2)}</td>
  //         <td>${result["amount_owed"].toFixed(2)}</td>
  //         <td>
  //           <input type="button" className="btn" onClick={(e) => this.props.handleEdit(result)} value="edit"></input>
  //         </td>
  //       </tr>
  //     );
  // }
  render() {
    if (this.state.dataStatus == "ready") {
      let options = this.state.optionList.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            Lot {item.fields.lot} | {item.fields.resident_text}
          </option>
        );
      });
      return (
        <section>
          <div>
            <section className="form-controls">
              <div className="input-control">
                <label>Lot</label>
                <select onChange={(e) => this.handleLotChange(e)}>
                  <option value="">select lot</option>
                  {options}
                </select>
              </div>
              <div className="input-control">
                {/* <label>Read Date</label>
                <select
                  name="read_date"
                  placeholder=""
                  onChange={(e) => this.handleInputChange(e, "read_date")}
                  required={true}
                  defaultValue={`${this.state.currentYear}`}
                >
                  <option value="">Select the Quarter</option>
                  <option value={`${this.state.currentYear}-03-31`}>Quarter 1</option>
                  <option value={`${this.state.currentYear}-06-30`}>Quarter 2</option>
                  <option value={`${this.state.currentYear}-09-30`}>Quarter 3</option>
                  <option value={`${this.state.currentYear}-12-31`}>Quarter 4</option>
                </select> */}
                <input
                  className="btn"
                  type="button"
                  onClick={(e) => this.handleSubmit(e)}
                  value="Search"
                />
              </div>
            </section>
          </div>
          {this.state.message}
          <Invoice {...this.state} />
        </section>
      );
    } else if (this.state.dataStatus == "waiting") {
      let options = this.state.optionList.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            Lot {item.fields.lot} | {item.fields.resident_text}
          </option>
        );
      });
      return (
        <section>
          <div>
            {this.state.message}
            <section className="form-controls">
              <div className="input-control">
                <label>Lot</label>
                <select onChange={(e) => this.handleLotChange(e)}>
                  <option value="">select lot</option>
                  {options}
                </select>
              </div>
              <div className="input-control">
                {/* <label>Read Date</label>
                <select
                  name="read_date"
                  placeholder=""
                  onChange={(e) => this.handleInputChange(e, "read_date")}
                  required={true}
                  defaultValue={`${this.state.currentYear}`}
                >
                  <option value="">Select the Quarter</option>
                  <option value={`${this.state.currentYear}-03-31`}>Quarter 1</option>
                  <option value={`${this.state.currentYear}-06-30`}>Quarter 2</option>
                  <option value={`${this.state.currentYear}-09-30`}>Quarter 3</option>
                  <option value={`${this.state.currentYear}-12-31`}>Quarter 4</option>
                </select> */}
                <input
                  className="btn"
                  type="button"
                  onClick={(e) => this.handleSubmit(e)}
                  value="Search"
                  // disabled={this.state.submitDisabled}
                />
              </div>
            </section>
            {this.state.message}
          </div>
        </section>
      );
    } else if (this.state.dataStatus == "loading") {
      let options = this.state.optionList.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            Lot {item.fields.lot} | {item.fields.resident_text}
          </option>
        );
      });
      return (
        <section>
          <div>
            <section className="form-controls">
              <div className="input-control">
                <label>Lot</label>
                <select onChange={(e) => this.handleLotChange(e)}>
                  <option value="">select lot</option>
                  {options}
                </select>
              </div>
              <div className="input-control">
                {/* <label>Read Date</label>
                <select
                  name="read_date"
                  placeholder=""
                  onChange={(e) => this.handleInputChange(e, "read_date")}
                  required={true}
                  defaultValue={`${this.state.currentYear}`}
                >
                  <option value="">Select the Quarter</option>
                  <option value={`${this.state.currentYear}-03-31`}>Quarter 1</option>
                  <option value={`${this.state.currentYear}-06-30`}>Quarter 2</option>
                  <option value={`${this.state.currentYear}-09-30`}>Quarter 3</option>
                  <option value={`${this.state.currentYear}-12-31`}>Quarter 4</option>
                </select> */}
                <input
                  className="btn"
                  type="button"
                  onClick={(e) => this.handleSubmit(e)}
                  value="Search"
                />
              </div>
            </section>
          </div>
          <div className="loading view-log">
            <img src="images/prop-loading.gif"></img>
          </div>
        </section>
      );
    }
  }
}
