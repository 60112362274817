import React, { Component } from "react";
import Moment from "react-moment";
import ListRecordsReadingItems from "./ListRecordsReadingItems";
export default class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataStatus: "loading",
      records: [],
      selectedLot: null,
      optionList: [],
      optionListObj: {},
      readSubmission: {},
      amountOwed: 0,
      creditApplied: 0,
    };
  }
  // componentDidMount() {
  //   this.calculateAmountDue(
  //     this.props.selectedLotDetails.amount_paid,
  //     this.props.selectedLotDetails.amount_owed,
  //     this.props.selectedLotDetails.credit
  //   );
  // }
  // calculateAmountDue(amount_paid, amount_owed, balance) {
  //   //if there is a balance
  //   if (amount_paid > amount_owed) {
  //     this.setState({ amountDue: 0 });
  //   } else {
  //     this.setState({ amountDue: Math.abs(balance) });
  //   }
  // }
  // calculateCreditApplied(owed, due) {
  //   // {`$${readSubmission.amount_owed - this.state.amountDue < 0 ? "0.00" : selectedLotDetails.credit}`}
  //   // if (owed - due < 0) {
  //   // }
  // }
  renderReadings(data) {
    console.log("data: ", data);

    return data.map((item, index) => {
      let d=new Date(item.reading_date);
      let year = d.getFullYear();
      let now = new Date();
      let yearNow = now.getFullYear();
      console.log(d);
      // if(item.balance  <= 0 && year < yearNow ){
      //   return;
      // }
      return <ListRecordsReadingItems data={item} key={index} />;
    });
  }
  render() {
    const {
      totals,
      read_date,
      selectedLotDetails,
      readSubmission,
      currentYear,
      currentQuarter,
      resident,
      selectedQuarter,
    } = this.props;
    return (
      <div className="invoice-box">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="top">
              <td colSpan="5">
                <table>
                  <tbody>
                    <tr>
                      <td className="title">
                        <img src="images/burnettlanding.jpg" />
                      </td>

                      <td>
                        Invoice #: {`${currentYear}0${currentQuarter}0${selectedLotDetails.lot}`}
                        <br />
                        Created: <Moment format="MMMM DD, YYYY">{Date.now()}</Moment>
                        <br />
                        Due: 30 days
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr className="information">
              <td colSpan="5">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        Burnett Landing HOA
                        <br />
                        C/O Margy Johnson
                        <br />
                        14210 282nd Ave E
                        <br />
                        Buckley, WA 98321
                      </td>

                      <td>
                        {resident.name}
                        <br />
                        {selectedLotDetails.address}
                        <br />
                        {`${selectedLotDetails.city}, ${selectedLotDetails.state} ${selectedLotDetails.zip_code}`}
                        <br />
                        {resident.email}
                        <br />
                        {resident.phone}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr className="heading">
              <td>Date</td>
              <td>Item</td>
              <td>Price</td>
              <td>Paid</td>
              <td>Due</td>
            </tr>

            {/* <tr className="item">
              <td></td>
              <td>Base rate $35.00 / month X 3 months </td>
              <td>$105.00</td>
              <td></td>
            </tr> */}
            {this.renderReadings(readSubmission)}
            {/* <tr className="item">
              <td>{`CCFs Used: ${readSubmission.CCF.toFixed(2)} | Gallons Used: ${readSubmission.gallons.toFixed(
                2
              )}`}</td>

              <td>{`$${(readSubmission.amount_owed - 105).toFixed(2)}`}</td>
            </tr> */}

            <tr className="total">
              <td></td>
              <td></td>
              <td></td>
              <td colSpan="2">Total: {`$${parseFloat(selectedLotDetails.amount_owed).toFixed(2)}`}</td>
            </tr>
            {/* <tr className="total">
              <td></td>
              <td>Amount Paid this billing cycle: {`-$${readSubmission.amount_paid.toFixed(2)}`}</td>
            </tr> */}
            <tr className="total">
              <td></td>
              <td></td>
              <td></td>
              <td colSpan="2">Payments: ${parseFloat(selectedLotDetails.amount_paid).toFixed(2)}</td>
            </tr>
            {/* <tr className="total">
              <td></td>
              <td>
                Amount Applied from Credit: {`$${readSubmission.amount_owed.toFixed(2)-totals.balance}`}
              </td>
            </tr> */}
            <tr className="total">
            <td></td>
              <td></td>
              <td></td>
              <td colSpan="2">Amount Due: ${selectedLotDetails.credit *-1 <0 ? "0.00": parseFloat(selectedLotDetails.credit *-1).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
