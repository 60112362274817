import axios from "axios";
const AIRTABLE_TOKEN = "Bearer patKDS5enHZFmVR0Y.142c9adaae39afd3a6a7f513a52fa0c981ec1afb5a6cc2b05d23a7cd29bbc1b9";
//@TODO: add timeouts
const appRequest = {
  getClosestAirport: (lat, long) => {
    // console.log("params: ", take);
    const key = "AIzaSyD_nRVglcu7ruDSFprC7MRfSCE9LZO5PxY";
    const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${long}&rankby=distance&type=airport&key=${key}`;
    //let progress = 0;
    // const currentTime = () => {
    //   const time = new Date();
    //   return time.getTime();
    // };
    // const start = currentTime();

    // Make RCDS Request
    return axios({
      url: url,
      method: "get",
    })
      .then((resp) => {
        // if (resp.data.success != true) {
        //   return Promise.reject(resp);
        // }
        // console.log("response:", resp);
        return resp.data;
      })
      .catch((resp) => {
        // console.log("error:", resp);
        return resp;
      });
  },
  getMeterReadings: (lotId = "", startDate = "", complex=false, method='') => {
    let suffix = `&filterByFormula={lot_number_search}='${lotId}'`;

    if (complex) {
      suffix = `&filterByFormula=AND(DATESTR({reading_date})='${startDate}',{lot_number_search}=${lotId})`;
      
    }
    if(method === "byDateOnly"){
      suffix = `&filterByFormula=AND(DATESTR({reading_date})='${startDate}')`;

    }

    //&filterByFormula=AND({PilotSearch}='James%20Simon',IS_AFTER({Duty%20Time%20In},'2019-06-01'),IS_BEFORE({Duty%20Time%20Out},'2019-06-30'))

    ///v0/appjWVRPyLcHPAz0A/Time%20Sheet?view=Time%20Sheet%20Grid&filterByFormula=AND({%27PilotSearch%27}=%27James%20Simon%27,IS_AFTER({Duty%20Time%20In},%272019-07-01%27))
    ///v0/appjWVRPyLcHPAz0A/Time%20Sheet?view=Time%20Sheet%20Grid&filterByFormula=AND(%7BPilotSearch%7D%3D'James%20Simon',IS_AFTER(%7BDuty%20Time%20In%7D,'2019-06-01'),IS_BEFORE(%7BDuty%20Time%20Out%7D,'2019-06-30'))
    ///v0/appjWVRPyLcHPAz0A/Time%20Sheet?view=Time%20Sheet%20Grid&filterByFormula=AND({'PilotSearch'}='James Simon',IS_AFTER({Duty Time In},'2019-07-01'))
    // }
    let url = `https://api.airtable.com/v0/appRL8cHNYnN7Dvwc/meter_readings?view=Grid%20view${suffix}`;
    // console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: AIRTABLE_TOKEN,
      },
    })
      .then((resp) => {
        console.log("axios: ", resp.data.records);
        if (resp.data.records.length > 0) {
          return resp.data.records;
        }
        return Promise.reject("No results");
      })
      .catch((error) => {
        // console.log(error);
        return Promise.reject(error);
      });
  },
  getLots: (lotId = "") => {
    let suffix = `&filterByFormula={lot_number_search}='${lotId}'`;

    if (lotId == "") {
      suffix = ``;
    }

    //&filterByFormula=AND({PilotSearch}='James%20Simon',IS_AFTER({Duty%20Time%20In},'2019-06-01'),IS_BEFORE({Duty%20Time%20Out},'2019-06-30'))

    ///v0/appjWVRPyLcHPAz0A/Time%20Sheet?view=Time%20Sheet%20Grid&filterByFormula=AND({%27PilotSearch%27}=%27James%20Simon%27,IS_AFTER({Duty%20Time%20In},%272019-07-01%27))
    ///v0/appjWVRPyLcHPAz0A/Time%20Sheet?view=Time%20Sheet%20Grid&filterByFormula=AND(%7BPilotSearch%7D%3D'James%20Simon',IS_AFTER(%7BDuty%20Time%20In%7D,'2019-06-01'),IS_BEFORE(%7BDuty%20Time%20Out%7D,'2019-06-30'))
    ///v0/appjWVRPyLcHPAz0A/Time%20Sheet?view=Time%20Sheet%20Grid&filterByFormula=AND({'PilotSearch'}='James Simon',IS_AFTER({Duty Time In},'2019-07-01'))
    // }
    let url = `https://api.airtable.com/v0/appRL8cHNYnN7Dvwc/lots?view=Grid%20view${suffix}`;
    // console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: AIRTABLE_TOKEN,
      },
    })
      .then((resp) => {
        // console.log("axios: ", resp.data.records);
        if (resp.data.records.length > 0) {
          return resp.data.records;
        }
        return Promise.reject("No results");
      })
      .catch((error) => {
        // console.log(error);
        return Promise.reject(error);
      });
  },
  getResidents: (id = "", name = "") => {
    let suffix = `&filterByFormula=AND({lot_number_search}='${id}', {Name='${name}})`;

    if (name == "") {
      suffix = `&filterByFormula=AND({lot_number_search}='${id}')`;
    }

    //&filterByFormula=AND({PilotSearch}='James%20Simon',IS_AFTER({Duty%20Time%20In},'2019-06-01'),IS_BEFORE({Duty%20Time%20Out},'2019-06-30'))

    ///v0/appjWVRPyLcHPAz0A/Time%20Sheet?view=Time%20Sheet%20Grid&filterByFormula=AND({%27PilotSearch%27}=%27James%20Simon%27,IS_AFTER({Duty%20Time%20In},%272019-07-01%27))
    ///v0/appjWVRPyLcHPAz0A/Time%20Sheet?view=Time%20Sheet%20Grid&filterByFormula=AND(%7BPilotSearch%7D%3D'James%20Simon',IS_AFTER(%7BDuty%20Time%20In%7D,'2019-06-01'),IS_BEFORE(%7BDuty%20Time%20Out%7D,'2019-06-30'))
    ///v0/appjWVRPyLcHPAz0A/Time%20Sheet?view=Time%20Sheet%20Grid&filterByFormula=AND({'PilotSearch'}='James Simon',IS_AFTER({Duty Time In},'2019-07-01'))
    // }
    let url = `https://api.airtable.com/v0/appRL8cHNYnN7Dvwc/residents/${id}`;
    // console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: AIRTABLE_TOKEN,
      },
    })
      .then((resp) => {
        console.log("axios: ", resp.data);
        if (resp.data) {
          return resp.data;
        }
        return Promise.reject("No results");
      })
      .catch((error) => {
        // console.log(error);
        return Promise.reject(error);
      });
  },
  getPayments: (lotId = "") => {
    let suffix = `&filterByFormula={lot_number_search}='${lotId}'`;

    if (lotId == "") {
      suffix = ``;
    }

    let url = `https://api.airtable.com/v0/appRL8cHNYnN7Dvwc/payments?view=Grid%20view${suffix}`;
    // console.log(url);
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: AIRTABLE_TOKEN,
      },
    })
      .then((resp) => {
        console.log("axios payments: ", resp.data.records);
        if (resp.data.records.length > 0) {
          return resp.data.records;
        }
        return Promise.reject("No results");
      })
      .catch((error) => {
        // console.log(error);
        return Promise.reject(error);
      });
  },
  createMeterRecord: (data = {}) => {
    let url = `https://api.airtable.com/v0/appRL8cHNYnN7Dvwc/meter_readings`;
    return axios({
      url: url,
      method: "POST",
      headers: {
        Authorization: AIRTABLE_TOKEN,
      },
      data: {
        records: [
          {
            fields: {
              reading_date: data["read_date"], //"2020-02-01",
              lot_number: [
                data["selectedLot"]
              ],
              previous_reading: data["previous_CCF"],
              current_reading: data["current_CCF"],
            },
          },
        ],
      },
    })
      .then((resp) => {
        console.log(resp);
        return resp.data;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },
  createPaymentRecord: (data = {}) => {
    let url = `https://api.airtable.com/v0/appRL8cHNYnN7Dvwc/payments`;
    return axios({
      url: url,
      method: "POST",
      headers: {
        Authorization: AIRTABLE_TOKEN,
      },
      data: {
        records: [
          {
            fields: {
              amount: data["amount"],
              reading: [
                data["readingIdForPaymentCreate"]
              ],
            },
          },
        ],
      },
    })
      .then((resp) => {
        console.log(resp);
        return resp.data;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  },
  getPilot: (id = "") => {
    let url = `https://api.airtable.com/v0/appjWVRPyLcHPAz0A/Pilots/${id}`;
    //&filterByFormula=OR(SEARCH(LOWER("${searchString}"),LOWER({part number})),SEARCH(LOWER("${searchString}"),LOWER({secondary part number})),SEARCH(LOWER("${searchString}"),LOWER({Product Name})))`
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: AIRTABLE_TOKEN,
      },
    }).then((resp) => {
      return resp.data.fields;
    });
  },
  getAllPilots: () => {
    let url = `https://api.airtable.com/v0/appjWVRPyLcHPAz0A/Pilots`;
    //&filterByFormula=OR(SEARCH(LOWER("${searchString}"),LOWER({part number})),SEARCH(LOWER("${searchString}"),LOWER({secondary part number})),SEARCH(LOWER("${searchString}"),LOWER({Product Name})))`
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer keycN2U7BBmQfMvw8",
      },
    }).then((resp) => {
      // console.log("axios all pilot: ", resp.data.records);
      return resp.data.records;
    });
  },
  saveSignature: (img, pilotId, date) => {
    let url = `https://api.airtable.com/v0/appjWVRPyLcHPAz0A/PilotTimeSheetSignature`;
    return axios({
      url: url,
      method: "POST",
      headers: {
        Authorization: AIRTABLE_TOKEN,
      },
      data: {
        fields: {
          Date: date,
          notes: "test",
          signature: img,
          Pilot: [pilotId],
        },
      },
    })
      .then((resp) => {
        return resp.data.records;
      })
      .catch((err) => {
        return err;
      });
  },
  getSignature: (pilot, date) => {
    const suffix = `&filterByFormula=AND(IS_SAME({Date},'${date}'),{Pilot}='${pilot}')`;
    let url = `https://api.airtable.com/v0/appjWVRPyLcHPAz0A/PilotTimeSheetSignature?view=Grid%20view${suffix}`;
    return axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: AIRTABLE_TOKEN,
      },
    })
      .then((resp) => {
        if (resp.data.records.length > 0) {
          return resp.data.records;
        }
        return Promise.reject("No resuls");
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
// export { getClosestAirport, getRecords };
export default appRequest;
