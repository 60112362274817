import React, { Component } from "react";
import Moment from "react-moment";
export default class PaymentsSubItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      amount,
      index,
      lot_number_search,
      createdTime,
      group
    } = this.props.data;
    return (
      <tr className={`sub-row`}>
        <td colSpan='4'><div>Payment made on <Moment format="MM/DD/YYYY">{createdTime}</Moment> for ${parseFloat(amount).toFixed(2)}</div></td>
      </tr>
    );
  }
}
