import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Moment from "react-moment";
import moment from "moment";
// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
import utilities from "../../helpers/utilities";

export default class SummaryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      system1_2_chart_array: [],
      system1v2v3_chart_array: [],
      system3_chart_array: [],
      burnett_v_wells: [],
    };
    Highcharts.setOptions({
      chart: {
        events: {
          beforePrint: function () {
            this.oldhasUserSize = this.hasUserSize;
            this.resetParams = [this.chartWidth, this.chartHeight, false];
            this.setSize(600, 400, false);
          },
          afterPrint: function () {
            this.setSize.apply(this, this.resetParams);
            this.hasUserSize = this.oldhasUserSize;
          },
        },
        reflow: true,
      },
      lang: {
        thousandsSep: ",",
      },
    });
  }
  PrintAction(mql) {
    //get the last item the charts array to make sure we are reflowing the current chart.
    let index = Highcharts.charts.length;
    index = index - 1;
    if (mql.matches) {
      Highcharts.charts[index].reflow();
    } else {
      Highcharts.charts[index].reflow();
    }
  }

  componentDidMount() {
    this.buildChartData(this.props.readSubmission);
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.updated >= this.props.updated) {
      this.buildChartData(this.props.readSubmission);
    }
  }

  //build arrays for highchart consumption

  buildChartData(data) {
    //water meter lot numbers
    //burnett water for system 1 and 2:   19
    //burnett water for system 3:         20
    //System 1:                           21
    //System 2:                           22
    //System 3:                           23

    //all pumphouse meters
    let pumpHouseData = data.filter((item) => {
      return item.lot_type == "pump house";
    });

    //sum all usage (not useful except to get well usage by subtracting consumption meters)
    let all_meter_sum = pumpHouseData.reduce((previousValue, currentValue) => {
      return { CCF_calc: previousValue.CCF_calc + currentValue.CCF_calc };
    });

    //----------system 1 and 2 -------------//
    //isolate system 1 and 2
    let sys1_2 = pumpHouseData.filter((item) => {
      return (
        item.lot_number_search == 19 ||
        item.lot_number_search == 21 ||
        item.lot_number_search == 22
      );
    });
    //isolate system 1 and 2 burnett usage
    let sys1_2_burnett = pumpHouseData.filter((item) => {
      return item.lot_number_search == 19;
    });

    //isolate system 1 and 2
    let sys1_2_wells = pumpHouseData.filter((item) => {
      return item.lot_number_search == 21 || item.lot_number_search == 22;
    });

    //isolate all consumption meters
    let consumption_meters = pumpHouseData.filter((item) => {
      return (
        item.lot_number_search == 21 ||
        item.lot_number_search == 22 ||
        item.lot_number_search == 23
      );
    });
    //isolate burnett meters
    let burnett_meters = pumpHouseData.filter((item) => {
      return item.lot_number_search == 19 || item.lot_number_search == 20;
    });
    //sum of burnett meters usage
    let burnett_meter_sum = burnett_meters.reduce(
      (previousValue, currentValue) => {
        return { CCF_calc: previousValue.CCF_calc + currentValue.CCF_calc };
      }
    );

    //sum of consumptive meters usage
    let consumptive_meter_sum = consumption_meters.reduce(
      (previousValue, currentValue) => {
        return { CCF_calc: previousValue.CCF_calc + currentValue.CCF_calc };
      }
    );

    //sum of system 1 and 2 consumption meters usage
    let sys1_2_consumption_meter_sum = sys1_2_wells.reduce(
      (previousValue, currentValue) => {
        return { CCF_calc: previousValue.CCF_calc + currentValue.CCF_calc };
      }
    );

    //system 1 consumption meters usage
    let sys1_meter = pumpHouseData.filter((item) => {
      return item.lot_number_search == 21;
    });

    //system 2 consumption meters usage
    let sys2_meter = pumpHouseData.filter((item) => {
      return item.lot_number_search == 22;
    });

    //sum of burnett usage on system 1 and 2
    let sys1_2_burnett_sum = sys1_2_burnett.reduce(
      (previousValue, currentValue) => {
        return { CCF_calc: previousValue.CCF_calc + currentValue.CCF_calc };
      }
    );
    //sum of all usage on system 1 and 2
    let sys1_2_sum = sys1_2.reduce((previousValue, currentValue) => {
      return { CCF_calc: previousValue.CCF_calc + currentValue.CCF_calc };
    });

    //-----------System 3 ------------//
    //isolate system 3
    let sys3 = pumpHouseData.filter((item) => {
      return item.lot_number_search == 20 || item.lot_number_search == 23;
    });
    console.log("line 158: ", sys3);
    //sum of all usage on system 3
    let sys3_sum = sys3.reduce((previousValue, currentValue) => {
      return { CCF_calc: previousValue.CCF_calc + currentValue.CCF_calc };
    });
    console.log("line 163: ", sys3_sum);
    //system 3 consumption meters usage
    let sys3_meter = pumpHouseData.filter((item) => {
      return item.lot_number_search == 23;
    });
    //system 3 burnett meter usage
    let sys3_burnett_meter = pumpHouseData.filter((item) => {
      return item.lot_number_search == 20;
    });

    //-------- Build arrays and write to state -------//
    let system1_2_chart_array = [];
    let system1v2v3_chart_array = [];
    let system3_chart_array = [];
    let burnett_v_wells = [];
    //get system 1 and 2 well usage by subtracting sys 1/2 burnett meters of the usage from the sum of all sys 1/2 meters
    system1_2_chart_array.push([
      "Well Water Usage",
      sys1_2_consumption_meter_sum.CCF_calc - sys1_2_burnett_sum.CCF_calc,
    ]);
    system1_2_chart_array.push([
      "Burnett Water Usage",
      sys1_2_burnett_sum.CCF_calc,
    ]);
    console.log("line 187: ", sys3_burnett_meter[0].CCF_calc);
    system1v2v3_chart_array.push(["System 1 Usage", sys1_meter[0].CCF_calc]);
    system1v2v3_chart_array.push(["System 2 Usage", sys2_meter[0].CCF_calc]);
    system1v2v3_chart_array.push(["System 3 Usage", sys3_meter[0].CCF_calc]);

    system3_chart_array.push([
      "Well Water Usage",
      sys3_meter[0].CCF_calc - sys3_burnett_meter[0].CCF_calc,
    ]);
    console.log("line 196: ", sys3_meter[0].CCF_calc - sys3_burnett_meter[0].CCF_calc,);
    system3_chart_array.push([
      "Burnett Water Usage",
      sys3_burnett_meter[0].CCF_calc,
    ]);
    
    //get well usage by subtracting burnett meters of the usage from the sum of all meters
    burnett_v_wells.push([
      "Well Water Usage",
      consumptive_meter_sum.CCF_calc - burnett_meter_sum.CCF_calc,
    ]);
    burnett_v_wells.push(["Burnett Water Usage", burnett_meter_sum.CCF_calc]);
    console.log('line 207: ', consumptive_meter_sum);
    this.setState({
      system1_2_chart_array,
      system1v2v3_chart_array,
      system3_chart_array,
      burnett_v_wells,
    });
  }

  //build highcharts
  renderChart_System_1_2vBurnett(report_type) {
    let options = {};
    // date = moment(this.props.read_date).format("MM/DD/YYYY");
    options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: '"System 1 and 2 Well Water vs Burnett Water Usage"',
        align: "center",
        verticalAlign: "top",
        y: 60,
      },

      //   tooltip: {
      //     pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b><br />CCF: {point.value}'
      // },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: "bold",
              color: "white",
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "75%"],
          size: "110%",
        },
      },
      series: [
        {
          type: "pie",
          name: "Usage Share",
          innerSize: "50%",
          data: this.state.system1_2_chart_array,
        },
      ],
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
  renderChart_System_3vBurnett(report_type) {
    let options = {};
    // date = moment(this.props.read_date).format("MM/DD/YYYY");
    options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: '"System 3 Well Water vs Burnett Water Usage"',
        align: "center",
        verticalAlign: "top",
        y: 60,
      },

      // tooltip: {
      //   pointFormat:
      //     "{series.name}: <b>{point.percentage:.1f}%</b><br />CCF: {$data.value}",
      // },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: "bold",
              color: "white",
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "75%"],
          size: "110%",
        },
      },
      series: [
        {
          type: "pie",
          name: "Usage Share",
          innerSize: "50%",
          data: this.state.system3_chart_array,
        },
      ],
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }

  renderChart_System_1v2v3(report_type) {
    let options = {};
    // date = moment(this.props.read_date).format("MM/DD/YYYY");
    options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "System 1, 2, and 3 Usage",
        align: "center",
        verticalAlign: "top",
        y: 60,
      },

      tooltip: {
        pointFormat:
          "{series.name}: <b>{point.percentage:.1f}%</b><br />CCF: {$data.value}",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: "bold",
              color: "white",
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "75%"],
          size: "110%",
        },
      },
      series: [
        {
          type: "pie",
          name: "Usage Share",
          innerSize: "50%",
          data: this.state.system1v2v3_chart_array,
        },
      ],
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
  renderChart_burnet_v_wells(report_type) {
    let options = {};
    // date = moment(this.props.read_date).format("MM/DD/YYYY");
    options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: '"Burnett City Water vs Well Water Usage"',
        align: "center",
        verticalAlign: "top",
        y: 60,
      },

      // tooltip: {
      //   pointFormat:
      //     "{series.name}: <b>{point.percentage:.1f}%</b><br />CCF: {point.value}",
      // },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: "bold",
              color: "white",
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "75%"],
          size: "110%",
        },
      },
      series: [
        {
          type: "pie",
          name: "Usage Share",
          innerSize: "50%",
          data: this.state.burnett_v_wells,
        },
      ],
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }

  render() {
    const {
      reading_date,
      gallons_calc,
      current_reading,
      previous_reading,
      CCF_calc,
      amount_owed,
      ccf_over_allotment,
      name,
      lot_number_search,
      lot_number,
      lot_type,
    } = this.props.readSubmission;
    const { report_type, optionListObj } = this.props;
    return (
      <React.Fragment>
        <div>{this.renderChart_System_1_2vBurnett()}</div>
        <div>{this.renderChart_System_3vBurnett()}</div>
        <div>{this.renderChart_burnet_v_wells()}</div>
        <div>{this.renderChart_System_1v2v3()}</div>
      </React.Fragment>
    );
  }
}
