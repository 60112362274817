import React, { Component } from "react";
import Moment from "react-moment";
import utilities from "../../helpers/utilities";
export default class LogRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataStatus: "loading",
      optionList: [],
      optionListObj: {},
      selectedLot: null,
      selectedLotDetails: null,
      read_date: "",
      previous_CCF: 0,
      current_CCF: "",
      readSubmission: {
        display: "display",
        status: "waiting...",
        response: "waiting...",
        CCF: "waiting...",
        gallons: "waiting...",
        amount_owed: 0,
      },
      currentYear: null,
      currentQuarter: null,
      submitDisabled: true,
      CCF_Units: true,
    };
  }
  componentDidMount() {
    this.buildOptions();
    this.getCurrentDate();
  }
  getCurrentDate() {
    const d = new Date();
    const y = d.getFullYear();
    const q = Math.floor((d.getMonth() + 3) / 3);
    this.setState({ currentYear: y, currentQuarter: q });
  }
  buildOptions(props) {
    return utilities.getLots().then((resp) => {
      console.log("16: ", resp.response);
      let optionList = [];
      this.setState({ optionList: resp.response, dataStatus: "ready", optionListObj: resp.records });
    });
  }
  handleLotChange(e) {
    e.preventDefault();

    this.setState(
      {
        selectedLot: e.target.value,
        selectedLotDetails: this.state.optionListObj[e.target.value],
        previous_CCF: "",
        current_CCF: "",
      },
      () => {
        utilities
          .getMeterReadings(this.state.selectedLotDetails.lot)
          .then((resp) => {
            console.log(resp);
            if (resp.response[0].current_reading) {
              let length = resp.response.length -1 ;
              console.log(length);
              this.setState({
                previous_CCF: resp.response[length].current_reading,
              });
            }
          })
          .catch((err) => {
            console.log("no meter reading yet for this lot.");
            return;
          });
      }
    );
  }
  renderDetails() {
    if (this.state.selectedLot != null) {
      return (
        <div className="css-typing">
          <p>
            {this.state.selectedLotDetails.resident_text} | {this.state.selectedLotDetails.address}
          </p>
        </div>
      );
    }
    return false;
  }
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      readSubmission: {
        display: "hide",
        status: "",
        response: "",
        CCF: "",
        gallons: "",
        amount_owed: 0,
      },
    });
    const data = { ...this.state };
    utilities
      .createMeterReading(data)
      .then((resp) => {
        console.log(resp);
        this.setState({
          previous_CCF: "",
          current_CCF: "",
          read_date: "",
          readSubmission: {
            display: "display",
            status: "read submitted",
            response: "connection established",
            CCF: resp.records[0].fields.CCF_calc,
            gallons: resp.records[0].fields.gallons_calc,
            amount_owed: resp.records[0].fields.amount_owed,
          },
          submitDisabled: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          readSubmission: {
            display: "display",
            status: "Sorry, an error occured",
            response: err.message.response.statusText,
            CCF: "Unknown",
            gallons: "Unknown",
            amount_owed: 0,
          },
        });
      });
  }
  handleInputChange(e, el) {
    if (el != "read_date") {
      if (el == "current_CCF" && !this.state.CCF_Units) {
        console.log("line 128: ", parseFloat(e.target.value / 748));
        this.setState({ [el]: parseFloat(e.target.value / 748) });
      } else {
        this.setState({ [el]: parseFloat(e.target.value) });
      }
    } else {
      this.setState({ [el]: e.target.value });
    }
    if (
      this.state.previous_CCF != "" &&
      this.state.current_CCF != "" &&
      this.state.read_date != "" &&
      this.state.selectedLot != null
    ) {
      this.setState({ submitDisabled: false });
    }
  }
  handleRadioChange(e) {
    // e.preventDefault();
    this.setState(
      (prevState) => ({ CCF_Units: !prevState.CCF_Units }),
      () => {
        if (this.state.current_CCF != null && this.state.CCF_Units == false) {
          this.setState({ current_CCF: parseFloat(this.state.current_CCF / 748) });
        }
        if (this.state.current_CCF != null && this.state.CCF_Units == true) {
          this.setState({ current_CCF: parseFloat(this.state.current_CCF * 748) });
        }
      }
    );
  }
  render() {
    if (this.state.dataStatus == "ready") {
      let options = this.state.optionList.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            Lot {item.fields.lot} | {item.fields.resident_text}
          </option>
        );
      });
      return (
        <section className="form-controls">
          <div className="input-control">
            <label>Lot</label>
            <select onChange={(e) => this.handleLotChange(e)}>
              <option value="">select lot</option>
              {options}
            </select>
          </div>
          <div>{this.renderDetails()}</div>
          <div className="input-control">
            <label>Read Date</label>
            <select
              name="read_date"
              placeholder=""
              onChange={(e) => this.handleInputChange(e, "read_date")}
              required={true}
              defaultValue={`${this.state.currentYear}`}
            >
              <option value="">Select the Quarter</option>
              <option value={`${this.state.currentYear}-03-31`}>Quarter 1</option>
              <option value={`${this.state.currentYear}-06-30`}>Quarter 2</option>
              <option value={`${this.state.currentYear}-09-30`}>Quarter 3</option>
              <option value={`${this.state.currentYear}-12-31`}>Quarter 4</option>
            </select>
            </div>
            <div className="input-control">
            <label>Year</label>
            <input
              type="text"
              name="currentYear"
              placeholder=""
              onChange={(e) => this.handleInputChange(e, "currentYear")}
              required={true}
              value={this.state.currentYear}
            ></input>
          </div>
          <div className="input-control">
            <label>Previous CCF</label>
            <input
              type="number"
              name="previous_CCF"
              placeholder=""
              onChange={(e) => this.handleInputChange(e, "previous_CCF")}
              value={this.state.previous_CCF}
            ></input>
          </div>
          <div className="input-control force-row">
            <label>
              <input
                type="radio"
                value="CCF"
                onChange={(e) => this.handleRadioChange(e)}
                name="unit"
                className="option-input radio"
                defaultChecked
              />
              CCF
            </label>
            <label>
              <input
                type="radio"
                value="gallons"
                onChange={(e) => this.handleRadioChange(e)}
                name="unit"
                className="option-input radio"
              />
              Gallons
            </label>
          </div>
          <div className="input-control">
            <label>Current Read</label>
            <input
              type="number"
              name="current_CCF"
              placeholder=""
              onChange={(e) => this.handleInputChange(e, "current_CCF")}
              //value={this.state.current_CCF}
            ></input>
          </div>
          <input
            className="btn"
            type="button"
            onClick={(e) => this.handleSubmit(e)}
            value={this.state.submitDisabled == true ? "waiting..." : "Submit"}
            disabled={this.state.submitDisabled}
          />
          <div>
            <div className={`css-typing ${this.state.readSubmission.display}`}>
              <p>Status: {this.state.readSubmission.status}</p>
              <p>Response: {this.state.readSubmission.response}</p>
              <p>CCF: {this.state.readSubmission.CCF}</p>
              <p>Gallons: {this.state.readSubmission.gallons}</p>
              <p>Amount: ${parseInt(this.state.readSubmission.amount_owed).toFixed(2)}</p>
            </div>
          </div>
        </section>
      );
    } else if (this.state.dataStatus == "loading") {
      return (
        <div className="loading">
          <img src="images/prop-loading.gif"></img>
        </div>
      );
    } else {
      return <div className="loading">waiting</div>;
    }
  }
}
