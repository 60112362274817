import React, { Component } from "react";
import App from "./App";
import NavBar from "./NavBar";

const AppWrapper = () => {
  return (
    <div>
      <App />
    </div>
  );
};

export default AppWrapper;
