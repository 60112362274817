import React, { Component } from "react";
import Moment from "react-moment";
import PaymentsSubItems from "./PaymentsSubItems";
export default class PaymentsItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderPayments(data, group) {
    console.log("data: ",data);
    return data.map((item, index) => {
      return <PaymentsSubItems data={item} key={index} group={group} />;
    });
  }
  render() {
    // const { payments } = this.props.paymentData;
    const {
      previous_reading,
      current_reading,
      index,
      reading_date,
      CCF_calc,
      gallons_calc,
      lot_number_search,
      amount_owed,
      name,
      payments,
      paymentDetails,
      balance
    } = this.props.data;
    return (
      <React.Fragment>
        <tr className={`group-${index}`}>
          <td>{reading_date}</td>
          <td>{name[0]}</td>
          <td>${amount_owed.toFixed(2)}</td>
          <td>${balance.toFixed(2)}</td>
        </tr>
          {this.renderPayments(paymentDetails, `group-${index}`)}

      </React.Fragment>
    );
  }
}
