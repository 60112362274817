import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Moment from "react-moment";
import moment from "moment";

import utilities from "../../helpers/utilities";
import MeterItem from "./MeterItem";
import SummaryItem from "./SummaryItem";
// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

export default class ListMeterReadings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      line_data: [],
      owed_data: [],
      first_read_date: "",
      last_read_date: "",
      date_labels: [],
      chart_options: {},
      updated: 0,
    };
    Highcharts.setOptions({
      chart: {
        events: {
          beforePrint: function () {
            this.oldhasUserSize = this.hasUserSize;
            this.resetParams = [this.chartWidth, this.chartHeight, false];
            this.setSize(600, 400, false);
          },
          afterPrint: function () {
            this.setSize.apply(this, this.resetParams);
            this.hasUserSize = this.oldhasUserSize;
          },
        },
        reflow: true,
      },
      lang: {
        thousandsSep: ",",
      },
    });
  }
  PrintAction(mql) {
    //get the last item the charts array to make sure we are reflowing the current chart.
    let index = Highcharts.charts.length;
    index = index - 1;
    if (mql.matches) {
      Highcharts.charts[index].reflow();
    } else {
      Highcharts.charts[index].reflow();
    }
  }

  componentDidMount() {
    this.processData(this.props.readSubmission, this.props.report_type);
    // this.renderChart(this.props.report_type);
    const mediaQueryList = window.matchMedia("print");
    mediaQueryList.addEventListener("change", this.PrintAction);
  }
  componentWillUnmount() {
    Highcharts.charts.shift();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.updated == this.state.updated &&
      this.report_type != "byLot"
    ) {
      this.processData(this.props.readSubmission, this.props.report_type);
    }
  }

  renderChart(report_type) {
    let options = {};
    switch (report_type) {
      case "byLot":
        date = moment(this.props.read_date).format("MM/DD/YYYY");
        options = {
          chart: {
            type: "spline",
          },
          title: {
            text: "Lot Usage Chart",
          },

          tooltip: {},
          yAxis: [
            {
              // Primary yAxis
              labels: {
                format: "{value} gal",
              },
              title: {
                text: "Gallons Used",
              },
              opposite: false,
            },
            {
              // Secondary yAxis
              gridLineWidth: 0,
              title: {
                text: "Dollars",
              },
              labels: {
                format: "${value}",
              },
              opposite: true,
            },
          ],
          xAxis: {
            tickWidth: 1,
            categories: true,
            minRange: 1,
            labels: {
              enabled: true,
              rotation: -45,
            },
            crosshair: true,
            tickmarkPlacement: "on",
          },
          series: [
            {
              name: "Amount",
              data: this.state.owed_data,
              pointPlacement: "on",
              yAxis: 1,
              type: "column",
            },
            {
              name: "Gallons Used",
              data: this.state.line_data,
              pointPlacement: "on",
            },
          ],
        };
        return <HighchartsReact highcharts={Highcharts} options={options} />;

      case "quarterly":
        let buildDate = `${this.props.currentYear}-${this.props.read_date}`;
        let date = moment(buildDate).format("MM/DD/YYYY");
        options = {
          chart: {
            type: "column",
          },
          title: {
            text: "Quarterly Usage Report by Lot",
          },
          subtitle: {
            text: date,
          },

          tooltip: {},
          yAxis: [
            {
              // Primary yAxis
              labels: {
                format: "{value} gal",
              },
              title: {
                text: "Gallons Used",
              },
              opposite: false,
            },
          ],
          xAxis: {
            categories: true,
            minRange: 1,
            labels: {
              enabled: true,
              format: "{value}",
            },
            crosshair: true,
          },
          series: [
            {
              name: "Gallons Used",
              data: this.state.line_data,

              yAxis: 0,
            },
          ],
        };
        return <HighchartsReact highcharts={Highcharts} options={options} />;
    }
  }
  processData(data, report_type) {
    let first_read_date = "";
    let last_read_date = "";
    let line_data = [];
    let date_labels = [];
    let owed_data = [];
    if (report_type === "quarterly") {
      data
        // .sort((a, b) => (b.reading_date * 1 > a.reading_date * 1 ? 1 : -1))
        .map((item, index, arr) => {
          date_labels.push(item.reading_date);
          owed_data.push([item.reading_date, item.amount_owed.toFixed(2) * 1]);
          if (item.lot_type == "pump house") {
            // line_data.push([item.lot_number_search, item.gallons_calc.toFixed(2) * 1]);
          } else {
            line_data.push([
              item.name[0].split(",")[0],
              item.gallons_calc.toFixed(2) * 1,
            ]);
          }
        });
      this.setState((prevState) => {
        return {
          ...prevState,
          line_data,
          first_read_date,
          last_read_date,
          date_labels,
          owed_data,

          updated: prevState.updated + 1,
        };
      });
    }
    if (report_type === "byLot") {
      data
        .sort((a, b) => {
          a.reading_date - b.reading_date;
          console.log("line 226: ", data);
        })
        .map((item, index, arr) => {
          line_data.push([item.reading_date, item.gallons_calc.toFixed(2) * 1]);
          // date_labels.push(item.reading_date);
          owed_data.push([item.reading_date, item.amount_owed.toFixed(2) * 1]);

          if (index === 0) {
            first_read_date = item.reading_date;
          }
          if (arr.length - 1 === index) {
            last_read_date = item.reading_date;
          }
        });

      line_data.reverse();
      owed_data.reverse();

      this.setState((prevState) => {
        return {
          ...prevState,
          line_data,
          first_read_date,
          last_read_date,
          date_labels,
          owed_data,
          updated: prevState.updated + 1,
        };
      });
    }
  }
  renderSummary(data, state) {
    //water meter lot numbers
    //burnett water for system 1 and 2:   19
    //burnett water for system 3:         20
    //System 1:                           21
    //System 2:                           22
    //System 3:                           23

    //all pumphouse meters
    let pumpHouseData = data.filter((item) => {
      return item.lot_type == "pump house";
    });
    //isolate pump house meters
    let all_data = pumpHouseData.filter((item) => {
      return (
        item.lot_number_search == 19 ||
        item.lot_number_search == 20 ||
        item.lot_number_search == 21 ||
        item.lot_number_search == 22 ||
        item.lot_number_search == 23
      );
    });
    console.log(all_data);
    if (all_data.length === 5) {
      return <SummaryItem readSubmission={data} {...state} />;
    } else {
      return false;
    }
  }
  renderReadings(data, report_type, optionListObj) {
    if (report_type === "quarterly") {
      return data
        .sort((a, b) =>
          a.lot_number_search * 1 > b.lot_number_search * 1 ? 1 : -1
        )
        .map((item, index) => {
          return (
            <MeterItem
              key={index}
              data={item}
              report_type={report_type}
              optionListObj={optionListObj}
            />
          );
        });
    }
    return data
      .sort((a, b) => (a.read_date * 1 > b.read_date * 1 ? 1 : -1))
      .map((item, index) => {
        return (
          <MeterItem
            key={index}
            data={item}
            report_type={report_type}
            optionListObj={optionListObj}
          />
        );
      });
  }

  render() {
    const {
      readSubmission,
      report_type,
      optionListObj,
      selectedLotDetails,
      selectedQuarter,
      currentYear,
    } = this.props;
    if (report_type === "byLot") {
      return (
        <section className="report-wrapper">
          <h4>
            Report for {selectedLotDetails.resident_text.split(",")[0]} and{" "}
            {selectedLotDetails.resident_text.split(",")[1]}
            <br />
            <span className="detail">Lot: {selectedLotDetails.lot}</span>
            <br />
            <span className="detail">
              Report generated on:{" "}
              {<Moment format="MM/DD/YYYY">{new Date()}</Moment>} at{" "}
              <Moment format="HH:MM:ss">{new Date()}</Moment>
            </span>
          </h4>
          <div className="chart">{this.renderChart(report_type)}</div>
          <section className="table grid">
            <div className="header-row">Read Date</div>
            <div className="header-row">Previous Reading</div>
            <div className="header-row">Current Reading</div>
            <div className="header-row">CCF Used</div>
            <div className="header-row">Gallons Used</div>
            <div className="header-row">CCF Over</div>
            <div className="header-row">Cost</div>
            {this.renderReadings(readSubmission, report_type, optionListObj)}
          </section>
        </section>
      );
    }
    if (report_type == "quarterly") {
      return (
        <section className="report-wrapper">
          <h4>
            Burnett Landing Water Usage Report for Quarter {selectedQuarter},{" "}
            {currentYear}
            <br />
            <span className="detail">
              Report generated on:{" "}
              {<Moment format="MM/DD/YYYY">{new Date()}</Moment>} at{" "}
              <Moment format="HH:MM:ss">{new Date()}</Moment>
            </span>
          </h4>
          <div className="chart">{this.renderChart(report_type)}</div>
          <section className="table grid">
            <div className="header-row">Name / Lot #</div>
            <div className="header-row">Read Date</div>
            <div className="header-row">Previous Reading</div>
            <div className="header-row">Current Reading</div>
            <div className="header-row">CCF Used</div>
            <div className="header-row">CCF Over</div>
            <div className="header-row">Cost</div>
            {this.renderReadings(readSubmission, report_type, optionListObj)}
          </section>
          <section>
            <h5>Summary</h5>
            <section className="summary-wrapper">
              {this.renderSummary(readSubmission, this.state)}
            </section>
          </section>
        </section>
      );
    }
  }
}
