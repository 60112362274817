import React, { Component } from "react";
import Moment from "react-moment";
import utilities from "../../helpers/utilities";
import ListRecords from "./ListRecords";
export default class ReportDash extends Component {


render(){
    return(
        <section className="reports">
          <div></div>
       <ListRecords handleNavigation={this.handleNavigation}
            {...this.state} />
       </section>
    );
}


}