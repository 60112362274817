import appRequest from "../helpers/appRequest";
const utilities = {
  handlePilotChange: (e) => {
    this.setState({ dataStatus: "loading" });
    // const index = e.nativeEvent.target.selectedIndex;
    // e.nativeEvent.target[index].text;
    this.getPilot(e.target.value);
    // this.getRecords(e.nativeEvent.target[index].text, this.state.startDate, this.state.endDate);
  },
  getMeterReadings(lot = "", startDate = "2019-01-01", complex = false, method='') {
    return appRequest
      .getMeterReadings(lot, startDate, complex, method)
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          element["fields"].id=element["id"];
          array.push(element["fields"]);
        });
        console.log(array);
        console.log(obj);
        return { records: obj, dataStatus: "ready", response: array };
      })
      .catch((error) => {
        console.log("26: ", error);
        return Promise.reject({ message: error, dataStatus: "error" });
      });
  },
  getLots(lot = "") {
    return appRequest
      .getLots(lot)
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        fields.forEach((element) => {
          obj[element["id"]] = element["fields"];
          array.push(element);
        });
        console.log(array);
        console.log(obj);
        return { records: obj, dataStatus: "ready", response: array };
      })
      .catch((error) => {
        // console.log("42: ", error);
        return { message: error, dataStatus: "error" };
      });
  },
  getPayments(lot = "") {
    return appRequest
      .getPayments(lot)
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let objByReading = {};
        let array = [];
        let tempAry = [];
        fields.forEach((element) => {
          element["fields"]["createdTime"] = element["createdTime"];
          obj[element["id"]] = element["fields"];

          array.push(element);
        });
        // fields.forEach((element) => {
        //   console.log("element: ", element);
        //   tempAry.push(element['fields']);
        //   objByReading[element['fields']['reading'][0]] = tempAry;
        // });
        console.log(array);
        console.log(obj);
        // console.log("objByReading: ",objByReading);
        return { records: obj, dataStatus: "ready", response: array };
      })
      .catch((error) => {
        // console.log("42: ", error);
        return { message: error, dataStatus: "error" };
      });
  },
  getResidents(id = "", name = "") {
    return appRequest
      .getResidents(id, name)
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        // fields.forEach((element) => {
        //   obj[element["id"]] = element["fields"];
        //   array.push(element["fields"]);
        // });
        console.log(array);
        console.log(obj);
        return { records: fields, dataStatus: "ready", response: fields };
      })
      .catch((error) => {
        console.log("66: ", error);
        return Promise.reject({ message: error, dataStatus: "error" });
      });
  },
  createMeterReading(data) {
    return appRequest
      .createMeterRecord(data)
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        console.log("response: ", resp);
        // console.log(obj);
        return resp;
      })
      .catch((error) => {
        // console.log("42: ", error);
        return Promise.reject({ message: error, dataStatus: "error" });
      });
  },
  createPaymentRecord(data) {
    return appRequest
      .createPaymentRecord(data)
      .then((resp) => {
        const fields = resp;
        let obj = {};
        let array = [];
        console.log("response: ", resp);
        // console.log(obj);
        return resp;
      })
      .catch((error) => {
        // console.log("42: ", error);
        return Promise.reject({ message: error, dataStatus: "error" });
      });
  },
};

export default utilities;
