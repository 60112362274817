// const google = window.google;
import React, { Component } from "react";
import utilities from "./helpers/utilities";
// import appRequest from "./helpers/appRequest";
import NavBar from "./NavBar";
// import OperationalControl from "./components/OperationalControl";
import ListRecords from "./components/LogTime/ListRecords";
import LogRecords from "./components/LogTime/LogRecords";
import Payments from "./components/LogTime/Payments";
import ReportDash from "./components/Reports/ReportsDash";
// import { defaultCipherList } from "constants";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //activeComponent: null,
      activeComponent: "home",
      records: [],
      dataStatus: "loading",
      selectedLot: null,
    };
    this.handleNavigation = this.handleNavigation.bind(this);
  }
  handleNavigation(e, component) {
    e.preventDefault();
    this.setState({
      activeComponent: component,
      message: "waiting...",
      records: [],
    });
    let resp = "";
  }

  showComponent(e) {
    switch (this.state.activeComponent) {
      case "viewlog":
        return (
          <ListRecords
            handleNavigation={this.handleNavigation}
            {...this.state}
          />
        );
      case "enterlog":
        return (
          <LogRecords
            handleNavigation={this.handleNavigation}
            {...this.state}
            handleSubmit={this.handleSubmit}
          />
        );
      case "payment":
        return (
          <Payments handleNavigation={this.handleNavigation} {...this.state} />
        );
      case "reports":
        return (
          <ReportDash
            handleNavigation={this.handleNavigation}
            {...this.state}
          />
        );
    }
  }
  render() {
    return (
      <div>
        <header>
          <h2>Burnett Landing Water Reading</h2>
          <nav className="navigation">
            <a href="" onClick={(e) => this.handleNavigation(e, "viewlog")}>
              Invoice
            </a>
            &nbsp;|&nbsp;
            <a href="" onClick={(e) => this.handleNavigation(e, "enterlog")}>
              Enter Log
            </a>
            &nbsp;|&nbsp;
            <a href="" onClick={(e) => this.handleNavigation(e, "payment")}>
              Payment
            </a>
            &nbsp;|&nbsp;
            <a href="" onClick={(e) => this.handleNavigation(e, "reports")}>
              Reports
            </a>
          </nav>
        </header>
        {this.showComponent()}
      </div>
    );
  }
}
export default App;
