import React, { Component } from "react";
import Invoice from "./Invoice.js";
import PaymentsTable from "./PaymentsTable";
import utilities from "../../helpers/utilities";
import PaymentRecordForm from "./PaymentRecordForm.js";
export default class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataStatus: "waiting",
      records: [],
      selectedLot: null,
      optionList: [],
      optionListObj: {},
      readSubmission: {},
      resident: {},
      selectedQuarter: null,
      read_date: "2019-01-01",
      submitDisabled: true,
      payments: [],
      paymentsObj: {},
      objByReading: {},
      showPaymentPanel: false,
      amount: 0,
      readingIdForPaymentCreate: null,
      updateTable: false,
    };
    this.handlePaymentSubmit = this.handlePaymentSubmit.bind(this);
    this.handleAddPayment = this.handleAddPayment.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  // if (this.state.activeComponent === "viewlog") {
  //   console.log("got here");
  //   utilities.getMeterReadings("none", "2019-01-01", "2020-02-01").then((resp) => {
  //     this.setState({ records: resp.response, dataStatus: "ready" });
  //   });
  // }
  componentDidMount() {
    this.buildOptions();
    // this.getCurrentDate();
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("prevState: ", prevState.updateTable, "currentState: ", this.state.updateTable);
    console.log(prevState.updateTable==this.state.updateTable);
    if(this.state.updateTable != prevState.updateTable)
    {
      this.handleSubmit();
    }
  }
  // getCurrentDate() {
  //   const d = new Date();
  //   const y = d.getFullYear();
  //   const q = Math.floor((d.getMonth() + 3) / 3);
  //   this.setState({ currentYear: y, currentQuarter: q });
  // }
  handleInputChange(e, el) {
    this.setState({ [el]: e.target.value });
    // if (
    //   this.state.previous_CCF != "" &&
    //   this.state.current_CCF != "" &&
    //   this.state.read_date != "" &&
    //   this.state.selectedLot != null
    // ) {
    //   this.setState({ submitDisabled: false });
    // }
  }
  handleAddPayment() {
    this.setState((prevState) => ({
      showPaymentPanel: !prevState.showPaymentPanel,
    }));
  }
  handlePaymentSubmit() {
    const data = { ...this.state };
    utilities
      .createPaymentRecord(data)
      .then((resp) => {
        console.log(resp);
        this.setState({
          // 
          updateTable: true,
          submitDisabled: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          updateTable: false,
          submitDisabled: true,
        });
      });
  }
  buildOptions(props) {
    return utilities.getLots().then((resp) => {
      console.log("16: ", resp.response);
      let optionList = [];
      this.setState({ optionList: resp.response, dataStatus: "formReady", optionListObj: resp.records });
    });
  }
  handleLotChange(e) {
    e.preventDefault();
    this.setState({
      selectedLot: e.target.value,
      selectedLotDetails: this.state.optionListObj[e.target.value],
      submitDisabled: false,
    });
  }
  // handleInputChange(e, el) {
  //   if (el == "read_date") {
  //     let index = e.nativeEvent.target.selectedIndex;

  //     this.setState({ [el]: e.target.value, selectedQuarter: e.nativeEvent.target[index].text.split(" ")[1] });
  //   } else {
  //     this.setState({ [el]: e.target.value });
  //   }

  //   if (this.state.selectedLot != null) {
  //     this.setState({ submitDisabled: false });
  //   }
  // }

  handleSubmit(e) {
    // e.preventDefault();
    this.setState({
      readSubmission: {
        status: "",
        response: "",
        CCF: "",
        gallons: "",
        amount_owed: 0,
      },
      dataStatus: "loading",
    });
    utilities
      .getMeterReadings(this.state.selectedLotDetails.lot, "", false)
      .then((resp) => {
        console.log("line 86: ", resp);
        return Promise.resolve(resp.response);
      })
      .then((response) => {
        utilities
          .getPayments(this.state.selectedLotDetails.lot)
          .then((resp) => {
            console.log("Payments: ", resp);
            let paymentsArr = [];
            let objByReading = {};
            let readSubmissionWithPayments = [];
            for (let i = 0; resp.response.length > i; i++) {
              paymentsArr.push(resp.response[i].fields);
            }
            for (let x = 0; response.length > x; x++) {
              // console.log("got here1 ", response.length);
              if (response[x].hasOwnProperty("payments")) {
                let tempArray = [];
                for (let i = 0; response[x].payments.length > i; i++) {
                  for (const property in resp.records) {
                    // console.log("got here ", response[x]);
                    if (response[x].payments[i] == property) {
                      // objByReading[this.state.readSubmission[x].payments[i]] = resp.records[property];
                      let value = response[x];
                      tempArray.push(resp.records[property]);
                      readSubmissionWithPayments.push((response[x]["paymentDetails"] = tempArray));
                      console.log("line: 111", value);
                    }
                    // console.log(`${property}: ${object[property]}`);
                  }
                }
              } else {
                readSubmissionWithPayments.push((response[x]["paymentDetails"] = []));
              }
            }

            this.setState({
              payments: paymentsArr,
              paymentsObj: resp.records,
              readSubmission: response,
              readSubmissionWithPayments: readSubmissionWithPayments,
              submitDisabled: true,
              dataStatus: "ready",
            });
          })
          .catch((err) => {
            let readSubmissionWithPayments = [];
            for (let x = 0; response.length > x; x++) {
              // console.log("got here1 ", response.length);
              if (response[x].hasOwnProperty("payments")) {
                let tempArray = [];
                // for (let i = 0; response[x].payments.length > i; i++) {
                //   // for (const property in resp.records) {
                //   //   // console.log("got here ", response[x]);
                //   //   if (response[x].payments[i] == property) {
                //   //     // objByReading[this.state.readSubmission[x].payments[i]] = resp.records[property];
                //   //     let value = response[x];
                //   //     tempArray.push(resp.records[property]);
                //   //     readSubmissionWithPayments.push((response[x]["paymentDetails"] = tempArray));
                //   //     console.log("line: 111", value);
                //   //   }
                //   //   // console.log(`${property}: ${object[property]}`);
                //   // }
                // }
              } else {
                readSubmissionWithPayments.push((response[x]["paymentDetails"] = []));
              }
            }
            console.log("from catch: ", response);
            this.setState({ readSubmission: response, dataStatus: "ready" });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          readSubmission: {
            display: "display",
            status: "Sorry, an error occured",
            CCF: "Unknown",
            gallons: "Unknown",
            amount_owed: 0,
          },
          dataStatus: "Sorry an error occured or no record found",
        });
      });
    utilities
      .getResidents(this.state.selectedLotDetails.residents[0])
      .then((resp) => {
        console.log(resp);
        this.setState({ resident: resp.records["fields"] });
      })
      .catch((err) => {});
  }

  render() {
    if (this.state.dataStatus == "formReady" || this.state.dataStatus == "ready") {
      let options = this.state.optionList.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            Lot {item.fields.lot} | {item.fields.resident_text}
          </option>
        );
      });
      return (
        <section>
          <div>
            <section className="form-controls">
              <div className="input-control">
                <label>Lot</label>
                <select onChange={(e) => this.handleLotChange(e)}>
                  <option value="">select lot</option>
                  {options}
                </select>
              </div>
              <div className="input-control">
                <input
                  className="btn"
                  type="button"
                  onClick={(e) => this.handleSubmit(e)}
                  value={this.state.submitDisabled == true ? "waiting..." : "Submit"}
                  disabled={this.state.submitDisabled}
                />
              </div>
            </section>
          </div>
          <PaymentsTable {...this.state} handleAddPayment={this.handleAddPayment} />
          <PaymentRecordForm
            {...this.state}
            handleInputChange={this.handleInputChange}
            handlePaymentSubmit={this.handlePaymentSubmit}
          />
        </section>
      );
    } else {
      let options = this.state.optionList.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            Lot {item.fields.lot} | {item.fields.resident_text}
          </option>
        );
      });
      return (
        <section>
          <div>
            <section className="form-controls">
              <div className="input-control">
                <label>Lot</label>
                <select onChange={(e) => this.handleLotChange(e)}>
                  <option value="">select lot</option>
                  {options}
                </select>
              </div>
              <div className="input-control">
                <input
                  className="btn"
                  type="button"
                  onClick={(e) => this.handleSubmit(e)}
                  value={this.state.submitDisabled == true ? "waiting..." : "Submit"}
                  disabled={this.state.submitDisabled}
                />
              </div>
            </section>
          </div>
          <p></p>
        </section>
      );
    }
  }
}
