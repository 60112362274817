import React, { Component } from "react";
import Moment from "react-moment";
import PaymentsSubItems from "./PaymentsSubItems";
export default class ListRecordsReadingItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    // const { payments } = this.props.paymentData;
    const {
      previous_reading,
      current_reading,
      index,
      reading_date,
      CCF_calc,
      gallons_calc,
      lot_number_search,
      amount_owed,
      name,
      payments,
      paymentDetails,
      balance,
      amount_paid
    } = this.props.data;
    return (
      <React.Fragment>
        <tr className="item">
          <td><Moment format="MM/DD/YY">{reading_date}</Moment></td>
          <td>{`CCFs: ${CCF_calc.toFixed(2)} | Gallons: ${gallons_calc.toFixed(2)}`}</td>
          <td>{`$${(amount_owed).toFixed(2)}`}</td>
          <td>{`$${(amount_paid).toFixed(2)}`}</td>
          <td>{`$${(balance).toFixed(2)}`}</td>
        </tr>
      </React.Fragment>
    );
  }
}
