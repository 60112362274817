import React, { Component } from "react";
import utilities from "../../helpers/utilities";
import ListMeterReadings from "./ListMeterReadings";

export default class ListRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear:"",
      dataStatus: "waiting",
      selectedLot: null,
      optionList: [],
      optionListObj: {},
      yearsAvailable: [],
      readSubmission: {},
      resident: {},
      selectedQuarter: null,
      message: null,
      totals: {},
      read_date: "",

      report_type: "quarterly",
    };
  }
  // if (this.state.activeComponent === "viewlog") {
  //   console.log("got here");
  //   utilities.getMeterReadings("none", "2019-01-01", "2020-02-01").then((resp) => {
  //     this.setState({ records: resp.response, dataStatus: "ready" });
  //   });
  // }
  componentDidMount() {
    this.buildOptions();
    this.getCurrentDate();
    this.buildYearsOptions(2019);
  }
  getCurrentDate() {
    const d = new Date();
    const y = d.getFullYear();
    const q = Math.floor((d.getMonth() + 3) / 3);
    this.setState({ currentYear: y, currentQuarter: q });
  }
  buildOptions(props) {
    return utilities.getLots().then((resp) => {
      console.log("16: ", resp.response);
      let optionList = [];
      this.setState({
        optionList: resp.response,
        dataStatus: "waiting",
        optionListObj: resp.records,
      });
    });
  }
  buildYearsOptions(startYear) {
    var currentYear = new Date().getFullYear(),
      years = [];
    startYear = startYear || 2019;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    this.setState({ yearsAvailable: years });
  }

  handleLotChange(e) {
    e.preventDefault();
    this.setState({
      selectedLot: e.target.value,
      selectedLotDetails: this.state.optionListObj[e.target.value],
    });
  }
  handleInputChange(e, el) {
      e.persist();
      let index = e.nativeEvent.target.selectedIndex;

      
      this.setState((prevState)=>{
        return {
          [el]: e.target.value,
          selectedQuarter: el=="currentYear"?prevState.selectedQuarter: e.nativeEvent.target[index].text.split(" ")[1],
          report_type: "quarterly",
          
        };},
        () => {
          let read_date = `${this.state.currentYear}-${this.state.read_date}`;
          utilities
            .getMeterReadings("", read_date, false, "byDateOnly")
            .then((resp) => {
              let readings = resp.response.sort((a, b) => {
                return a.reading_date - b.reading_date;
            });  
            
              this.setState({
                readSubmission: readings,
                submitDisabled: true,
                dataStatus: "ready",
                message: null,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                readSubmission: {
                  display: "display",
                  status: "Sorry, an error occured",
                  CCF: "Unknown",
                  gallons: "Unknown",
                  amount_owed: 0,
                },
                dataStatus: "waiting",
                message: err.message,
              });
            });
        }
  );
    
  }
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      readSubmission: {
        status: "",
        response: "",
        CCF: "",
        gallons: "",
        amount_owed: 0,
      },
      dataStatus: "loading",
      report_type: "byLot",
    });
    utilities
      .getMeterReadings(this.state.selectedLotDetails.lot)
      .then((resp) => {
        var result = resp.response.reduce(function (acc, v) {
          //acc[v.lot_number_search] = (acc[v.lot_number_search] || 0) + v.amount_owed;
          return acc + v.amount_owed;
        }, 0);
        var balance = resp.response.reduce(function (acc, v) {
          //acc[v.lot_number_search] = (acc[v.lot_number_search] || 0) + v.amount_owed;
          return acc + v.balance;
        }, 0);
        this.setState({ totals: { owed: result, balance: balance } });
      });
    utilities
      .getMeterReadings(
        this.state.selectedLotDetails.lot,
        this.state.read_date,
        false
      )
      .then((resp) => {
        
       let readings = resp.response.sort((a,b) => {
          var aComps = a.reading_date.split("-");
          var bComps = b.reading_date.split("-");
          var aDate = new Date(aComps[2], aComps[1], aComps[0]);
          var bDate = new Date(bComps[2], bComps[1], bComps[0]);
          bDate.getTime() - aDate.getTime();
      });


      //    readings = resp.response.sort((a, b) => {
      //     return b.reading_date - a.reading_date;
      // });  
      console.log("line 152: ", readings);
        this.setState({
          readSubmission: readings,
          submitDisabled: true,
          dataStatus: "ready",
          message: null,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          readSubmission: {
            display: "display",
            status: "Sorry, an error occured",
            CCF: "Unknown",
            gallons: "Unknown",
            amount_owed: 0,
          },
          dataStatus: "waiting",
          message: err.message,
        });
      });
    utilities
      .getResidents(this.state.selectedLotDetails.residents[0])
      .then((resp) => {
        console.log(resp);
        this.setState({ resident: resp.records["fields"] });
      })
      .catch((err) => {});
  }

  render() {
    let yearOptions = this.state.yearsAvailable.map((item, index) => {
      
      return (
        <option key={index} value={item} >
          {item}
        </option>
      );
    });
    if (this.state.dataStatus == "ready") {
      let options = this.state.optionList.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            Lot {item.fields.lot} | {item.fields.resident_text}
          </option>
        );
      });

      return (
        <section>
          <section className="form-controls">
            <div className="input-control">
              <label>Year</label>
              <select
                name="currentYear"
                
                onChange={(e) => this.handleInputChange(e, "currentYear")}
                
                value={this.state.currentYear}
              >
                <option value="">Select a Year</option>
                {yearOptions}
              </select>
            </div>
            <div className="input-control">
              <label>Quarterly Report</label>
              <select
                name="read_date"
                onChange={(e) => this.handleInputChange(e, "read_date")}
                defaultValue={`${this.state.read_date}`}
              >
                <option value="">Select the Quarter</option>
                <option value={`03-31`}>
                  Quarter 1
                </option>
                <option value={`06-30`}>
                  Quarter 2
                </option>
                <option value={`09-30`}>
                  Quarter 3
                </option>
                <option value={`12-31`}>
                  Quarter 4
                </option>
              </select>
            </div>
          </section>
          <div>
            <section className="form-controls">
              <div className="input-control">
                <label>Usage Report By Lot</label>
                <select
                  name="lot_change"
                  onChange={(e) => this.handleLotChange(e)}
                >
                  <option value="">select lot</option>
                  {options}
                </select>
              </div>
              <div className="input-control">
                {/* <label>Quarterly Report</label>
                <select
                  name="read_date"
                  placeholder=""
                  onChange={(e) => this.handleInputChange(e, "read_date")}
                  required={true}
                  defaultValue={`${this.state.currentYear}`}
                >
                  <option value="">Select the Quarter</option>
                  <option value={`${this.state.currentYear}-03-31`}>Quarter 1</option>
                  <option value={`${this.state.currentYear}-06-30`}>Quarter 2</option>
                  <option value={`${this.state.currentYear}-09-30`}>Quarter 3</option>
                  <option value={`${this.state.currentYear}-12-31`}>Quarter 4</option>
                </select> */}
                <input
                  className="btn"
                  type="button"
                  onClick={(e) => this.handleSubmit(e)}
                  value="Search"
                />
              </div>
            </section>
          </div>
          {this.state.message}
          <ListMeterReadings {...this.state} />
        </section>
      );
    } else if (this.state.dataStatus == "waiting") {
      let options = this.state.optionList.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            Lot {item.fields.lot} | {item.fields.resident_text}
          </option>
        );
      });
      return (
        <section>
          <div>
            <section className="form-controls">
              <div className="input-control">
                <label>Year</label>
                <select
                  name="currentYear"
                
                  onChange={(e) => this.handleInputChange(e, "currentYear")}
                  
                  value={this.state.currentYear}
                >
                  <option value="">Select a Year</option>
                  {yearOptions}
                </select>
              </div>
              <div className="input-control">
                <label>Quarterly Report</label>
                <select
                  name="read_date"
                  placeholder=""
                  onChange={(e) => this.handleInputChange(e, "read_date")}
                  required={true}
                  defaultValue={`${this.state.read_date}`}
                >
                  <option value="">Select the Quarter</option>
                  <option value={`03-31`}>
                  Quarter 1
                </option>
                <option value={`06-30`}>
                  Quarter 2
                </option>
                <option value={`09-30`}>
                  Quarter 3
                </option>
                <option value={`12-31`}>
                    Quarter 4
                  </option>
                </select>
              </div>
            </section>
            <section className="form-controls">
              <div className="input-control">
                <label>Usage Report By Lot</label>
                <select
                  name="lot_change"
                  onChange={(e) => this.handleLotChange(e)}
                >
                  <option value="">select lot</option>
                  {options}
                </select>
              </div>
              <div className="input-control">
                {/* <label>Quarterly Report</label>
                <select
                  name="read_date"
                  placeholder=""
                  onChange={(e) => this.handleInputChange(e, "read_date")}
                  required={true}
                  defaultValue={`${this.state.currentYear}`}
                >
                  <option value="">Select the Quarter</option>
                  <option value={`${this.state.currentYear}-03-31`}>Quarter 1</option>
                  <option value={`${this.state.currentYear}-06-30`}>Quarter 2</option>
                  <option value={`${this.state.currentYear}-09-30`}>Quarter 3</option>
                  <option value={`${this.state.currentYear}-12-31`}>Quarter 4</option>
                </select> */}
                <input
                  className="btn"
                  type="button"
                  onClick={(e) => this.handleSubmit(e)}
                  value="Search"
                  // disabled={this.state.submitDisabled}
                />
              </div>
            </section>
            {this.state.message}
          </div>
        </section>
      );
    } else if (this.state.dataStatus == "loading") {
      let options = this.state.optionList.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            Lot {item.fields.lot} | {item.fields.resident_text}
          </option>
        );
      });
      return (
        <section>
          <div>
            <section className="form-controls">
              <div className="input-control">
                <label>Year</label>
                <select
                  name="currentYear"
                
                  onChange={(e) => this.handleInputChange(e, "currentYear")}
                  
                  value={this.state.currentYear}
                >
                  <option value="">Select a Year</option>
                  {yearOptions}
                </select>
              </div>
              <div className="input-control">
                <label>Quarterly Report</label>
                <select
                  name="read_date"
                  placeholder=""
                  onChange={(e) => this.handleInputChange(e, "read_date")}
                  required={true}
                  defaultValue={`${this.state.read_date}`}
                >
                  <option value="">Select the Quarter</option>
                  <option value={`03-31`}>
                  Quarter 1
                </option>
                <option value={`06-30`}>
                  Quarter 2
                </option>
                <option value={`09-30`}>
                  Quarter 3
                </option>
                <option value={`12-31`}>
                    Quarter 4
                  </option>
                </select>
              </div>
            </section>
            <section className="form-controls">
              <div className="input-control">
                <label>Usage Report By Lot</label>
                <select
                  name="lot_change"
                  onChange={(e) => this.handleLotChange(e)}
                >
                  <option value="">select lot</option>
                  {options}
                </select>
              </div>
              <div className="input-control">
                {/* <label>Quarterly Report</label>
                <select
                  name="read_date"
                  placeholder=""
                  onChange={(e) => this.handleInputChange(e, "read_date")}
                  required={true}
                  defaultValue={`${this.state.currentYear}`}
                >
                  <option value="">Select the Quarter</option>
                  <option value={`${this.state.currentYear}-03-31`}>Quarter 1</option>
                  <option value={`${this.state.currentYear}-06-30`}>Quarter 2</option>
                  <option value={`${this.state.currentYear}-09-30`}>Quarter 3</option>
                  <option value={`${this.state.currentYear}-12-31`}>Quarter 4</option>
                </select> */}
                <input
                  className="btn"
                  type="button"
                  onClick={(e) => this.handleSubmit(e)}
                  value="Search"
                />
              </div>
            </section>
          </div>
          <div className="loading view-log">
            <img src="images/prop-loading.gif"></img>
          </div>
        </section>
      );
    }
  }
}
