import React, { Component } from "react";
import Moment from "react-moment";
import utilities from "../../helpers/utilities";
export default class PaymentRecordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataStatus: "loading",
      optionList: [],
      optionListObj: {},
      selectedLot: null,
      selectedLotDetails: null,
      read_date: "",
      previous_CCF: 0,
      current_CCF: "",
      readSubmission: {
        display: "display",
        status: "waiting...",
        response: "waiting...",
        CCF: "waiting...",
        gallons: "waiting...",
        amount_owed: 0,
      },
      currentYear: null,
      currentQuarter: null,
      submitDisabled: true,
      CCF_Units: true,
    };
  }
  componentDidMount() {
    // this.buildOptions();
    this.getCurrentDate();
  }
  getCurrentDate() {
    const d = new Date();
    const y = d.getFullYear();
    const q = Math.floor((d.getMonth() + 3) / 3);
    this.setState({ currentYear: y, currentQuarter: q });
  }
  // buildOptions(props) {
  //   return this.state.readSubmissions.then((resp) => {
  //     console.log("16: ", resp.response);
  //     let optionList = [];
  //     this.setState({ optionList: resp.response, dataStatus: "ready", });
  //   });
  // }
  handleReadingChange(e) {
    e.preventDefault();
    this.setState({
      selectedReading: e.target.value,
    });
  }
  renderDetails() {
    if (this.state.selectedLot != null) {
      return (
        <div className="css-typing">
          <p>
            {this.state.selectedLotDetails.resident_text} | {this.state.selectedLotDetails.address}
          </p>
        </div>
      );
    }
    return false;
  }

  render() {
    if (this.props.showPaymentPanel) {
      let options = this.props.readSubmission.map((item, index) => {
        if (item.balance > 0) {
          return (
            <option key={index} value={item.id}>
              Date {item.reading_date} | balance: {item.balance}
            </option>
          );
        }
        return "";
      });
      return (
        <section className="form-controls">
          <div className="input-control">
            <label>Post Payment against item</label>
            <select onChange={(e) => this.props.handleInputChange(e, "readingIdForPaymentCreate")}>
              <option value="">select item</option>
              {options}
            </select>
          </div>
          {/* <div>{this.renderDetails()}</div> */}
          <div className="input-control">
            <label>Amount</label>
            <input
              type="text"
              name="amount"
              placeholder=""
              onChange={(e) => this.props.handleInputChange(e, "amount")}
              value={this.state.amount}
            ></input>
          </div>
          <input
            className="btn"
            type="button"
            onClick={(e) => this.props.handlePaymentSubmit(e)}
            value={"Submit"}
            disabled={false}
          />
          <div>
            <div className={`css-typing ${this.state.readSubmission.display}`}>
              <p>Status: {this.props.readSubmission.status}</p>
              <p>Response: {this.props.readSubmission.response}</p>
              {/* <p>CCF: {this.state.readSubmission.CCF}</p>
              <p>Gallons: {this.state.readSubmission.gallons}</p>
              <p>Amount: ${parseInt(this.state.readSubmission.amount_owed).toFixed(2)}</p> */}
            </div>
          </div>
        </section>
      );
    } else {
      return false;
    }
  }
}
