// src/components/NavBar.js

import React from "react";

const NavBar = () => {
  return (
    <div>
      <div></div>
    </div>
  );
};

export default NavBar;
