import React, { Component } from "react";
import Moment from "react-moment";
import PaymentItems from "./PaymentsItems";
export default class PaymentsTable extends Component {
  constructor(props) {
    super(props);
  }
  renderRows(meterData, residentData) {
    return meterData.map((item, index) => {
      return <PaymentItems data={item} key={index} residentData={residentData} />;
    });
  }
  render() {
    if (this.props.dataStatus === "ready") {
      return (
        <div className="invoice-box tbl">
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Amount Due</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>{this.renderRows(this.props.readSubmission, this.props.selectedLotDetails)}</tbody>
          </table>
          <div className="input-control">
                <input
                  className="btn"
                  type="button"
                  onClick={(e) => this.props.handleAddPayment(e)}
                  value={this.props.showPaymentPanel ? "Close Payment Panel": "Add Payment"}
                  // disabled={this.state.submitDisabled}
                />
              </div>
        </div>
      );
    } else {
      return <p></p>;
    }
  }
}
