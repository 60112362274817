import React, { Component } from "react";
import Moment from "react-moment";

import utilities from "../../helpers/utilities";

export default class MeterItem extends Component {

  renderLotInfo(data){
    if(data.lot_type==="full"){
      if(data.lot == 2) {
        return(
          <div>{data.resident_text.split(",")[0]} <br /> <span className="detail">Lot: {data.lot} *adjusted*</span></div>
        
      );
    }
      return(
        <div>{data.resident_text.split(",")[0]} <br /> <span className="detail">Lot: {data.lot}</span></div>
    );
    }
    return(
        <div>{data.resident_text.split(",")[0]}<br /> <span className="detail">{data.lot_type}</span></div>
    );
  }
  render() {
    const {
      reading_date,
      gallons_calc,
      current_reading,
      previous_reading,
      CCF_calc,
      amount_owed,
      ccf_over_allotment,
      name,
      lot_number_search,
      lot_number,
      lot_type
    } = this.props.data;
    const { report_type, optionListObj } = this.props;
    if (report_type === "byLot") {
      return (
        <React.Fragment>
          <div>
            <Moment format="MM/DD/YYYY">{reading_date}</Moment>
          </div>
          <div>{previous_reading.toFixed(2).toLocaleString()}</div>
          <div>{current_reading.toFixed(2).toLocaleString()}</div>
          <div>{CCF_calc.toFixed(2).toLocaleString()}</div>
          <div>{gallons_calc.toFixed(2).toLocaleString()}</div>
          <div>{lot_type=="pump house"?"--":ccf_over_allotment.toFixed(2).toLocaleString()}</div>
          <div>${amount_owed.toFixed(2).toLocaleString()}</div>
        </React.Fragment>
      );
    }
    if (report_type === "quarterly") {
      return (
        <React.Fragment>
          {this.renderLotInfo(optionListObj[lot_number[0]])}
          {/* <div>{name[0].split(',')[0]} / {lot_number_search}</div> */}
          <div>
            <Moment format="MM/DD/YYYY">{reading_date}</Moment>
          </div>
          <div>{previous_reading.toFixed(2).toLocaleString()}</div>
          <div>{current_reading.toFixed(2).toLocaleString()}</div>
          <div>{CCF_calc.toFixed(2).toLocaleString()}</div>
          <div>{lot_type=="pump house"?"--":ccf_over_allotment.toFixed(2).toLocaleString()}</div>
          <div>${amount_owed.toFixed(2).toLocaleString()}</div>
        </React.Fragment>
      );
    }
  }
}
